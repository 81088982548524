<template>
  <v-container fluid>
    <v-list lines="one">
      <v-list-item v-for="item in categories" :key="item.id" @click="onClick(item)">
        <v-list-item-title v-if="item.extra == null || !item.extra.topic">{{ item.title }}</v-list-item-title>
        <v-list-item-content v-else>
          <v-card>
            <v-card-text>
              {{ item.title }}
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "CategoryPage",
  data() {
    return {
      allCategories: [],
      categories: []
    };
  },
  computed: {
    ...mapState(["client", "company"]),
  },
  created() {
    this.setTitle("Где проблема?");

    this.load();
    this.$watch(
      () => this.$route.params,
      () => this.load()
    )
  },
  methods: {
    async load() {
      this.allCategories = await this.client.wrapEmit("miniapps/angry.categories", { });
      this.categories = this.allCategories.filter(item => item.parentId == this.$route.params.categoryId);
      // показываем только те темы, в которых есть шаблон для УК/ТСЖ
      this.categories = this.categories.filter(item => {
        if (item.extra != null && item.extra.topic) {
          for (let tmpl of item.companyRefs) {
            if ([1,2].includes(tmpl.extra.toCompanyId)) return true;
          }
          return false;
        }
        return true;
      });
    },
    onClick(category) {
      if (this.hasChildren(category)) {
        this.$router.push({ path: `/create/category/${category.id}` });
        return;
      }
      this.setCategory(category);
      this.$router.push({ path: "/create" });
    },
    hasChildren(category) {
      return this.allCategories.filter(item => item.parentId == category.id).length != 0;
    },
    ...mapMutations(["setTitle", "setCategory"]),
  }
};
</script>