<template>
  <v-card>
    <v-card-title>Пригласите соседа</v-card-title>
    <v-card-subtitle>Вы можете пригласить соседей, разместив пост у себя в ленте, либо в сообществе</v-card-subtitle>
    <v-card-text v-if="showSubText">Вы всегда можете вернуться к этому окну со страницы Настройки</v-card-text>
    <v-card-actions>
      <v-btn dark color="#1F7087" :rounded="true" @click="sendMessage">
        <v-icon>mdi-send-outline</v-icon>
        &nbsp;{{ txtSendVK }}
      </v-btn>
      <v-btn dark color="#1F7087" :rounded="true" @click="copyRefLink">
        <v-icon>mdi-tray-arrow-up</v-icon>
        &nbsp;{{ txtSendLink }}
      </v-btn>
    </v-card-actions>
    <br />
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" :color="toast.color" @close="closeToast" />
  </v-card>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import Toast from "./ToastComponent";
import uriParse from "url-parse";

export default {
  name: "RefCardComponent",
  props: {
    link: String,
    showSubText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobile: false,
      txtSendVK: "Поделиться в ВК",
      txtSendLink: "Отправить ссылку",
      toast: {
        show: false,
        text: null,
        color: null
      },
    };
  },
  created() {
    const uri = uriParse(window.location.href, true);
    if (uri.query.vk_platform != null) {
      this.isMobile = uri.query.vk_platform.indexOf("mobile") != -1;
    }
    this.txtSendVK = this.isMobile ? "Поделиться" : "Поделиться в ВК";
    this.txtSendLink = this.isMobile ? "Ссылка" : "Отправить ссылку";
  },
  methods: {
    closeToast() {
      this.toast.show = !this.toast.show;
    },
    copyRefLink() {
      console.log("скопировать ссылку в память");
      bridge.send("VKWebAppCopyText", { text: this.link }).then((data) => { 
        if (data.result) {
          // Текст скопирован в буфер обмена
          console.log("OK");
        } else {
          // Ошибка
          console.log("error");
          navigator.clipboard.writeText(this.link);
        }
      }).catch((error) => {
        // Ошибка
        console.log(error);
        navigator.clipboard.writeText(this.link);
      });
      this.showToast("Ссылка на приложение скопирована в память");
    },
    sendMessage() {
      console.log("написать пост в личный блог");
      bridge.send("VKWebAppShare", { link: this.link }).then((data) => { 
        if (data.result) {
          // Запись размещена
          console.log("OK");
        }
      }).catch((error) => {
        // Ошибка
        console.log(error);
      });
    },
    showToast(text, color) {
      this.toast.text = text;
      this.toast.show = true;
      this.toast.color = null;
      if (color) this.toast.color = color;
    }
  },
  components: {
    Toast
  },
}
</script>
