export default {
  setTitle(state, title) {
    state.pageName = title;
  },
  setClient(state, client) {
    state.client = client;
  },
  setUser(state, user) {
    state.user = user;
  },
  setCategory(state, category) {
    state.category = category;
  },
  setCompany(state, company) {
    state.company = company;
  },
  setHouse(state, house) {
    state.house = house;
    state.ready.house = true;
  },
  setFlat(state, flat) {
    if (state.flats == null) state.flats = [];
    const flats = state.flats.filter(item => item.number == flat.number);
    if (flats.length == 0) state.flats.push(flat);
  },
  setFlats(state, flats) {
    state.flats = flats;
    state.ready.flats = true;
  },
  setChannelsReady(state, { channel, status }) {
    state.ready[channel] = status;
  },
  clearAll(state) {
    state.user = null;
    state.house = null;
    state.flats = null;

    state.category = null;
    state.company = null;

    state.ready.house = false;
    state.ready.flats = false;
  }
};