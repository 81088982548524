<template>
  <SettingsComponent refLink="https://vk.com/app51805704" />
</template>

<script>
import SettingsComponent from "../lib/dom24x7Bridge/components/SettingsComponent.vue";

export default {
  name: "SettingsPage",
  components: {
    SettingsComponent
  },
};
</script>