<template>
  <v-container fluid>
    <div v-if="isMobile" class="top-spacer"></div>
    <v-form>
      <v-container v-if="!showSmsCodeForm">
        <v-row>
          <v-col>
            <v-text-field v-if="!isVKuser && !isReg"
              v-model="mobile"
              prefix="+7"
              append-icon="mdi-phone-plus-outline"
              :rules="phoneRules"
              :counter="10"
              label="Ваш номер телефона"
              type="number"
              required
              @click:append="getVKmobile"
            />
            <template v-if="isReg">
              Укажите свой дом на карте
              <YandexMap :coords="mapCenter" height="360px" @click="onMapClick" />
              <p><b>Вы выбрали адрес:</b> {{ address }}</p>
              <v-text-field v-model="flat" :hint="flatHint" label="Укажите номер квартиры" />
            </template>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn x-large color="primary" dark @click="auth" :loading="isLoaded">{{ sendBtnCaption }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col>
            <v-text-field v-model="smsCode" :rules="smsCodeRules" :counter="4" label="Код авторизации" hint="Необходимо указать 4 цифры из смс" required />
            <p class="font-weight-light">Мы отправили вам смс сообщение с кодом авторизации</p>
            <p v-if="waitSeconds > 0" class="font-weight-light">Если смс сообщение не пришло, то можно перезапросить его через {{ waitSeconds }} сек.</p>
            <p v-else class="font-weight-light">Теперь вы можете вновь <a @click="authByMobile">запросить</a> смс сообщение</p>
            <p class="font-weight-light">Также можете <a @click="showPhoneField">вернуться</a> на страницу ввода мобильного номера</p>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col>
            <v-btn x-large color="primary" @click="sendSmsCode" :disabled="disableSendSmsBtn()">Отправить</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <p v-if="!isReg && !showSmsCodeForm" class="font-weight-light">
      <slot></slot>
    </p>
    <p v-if="!isReg && !showSmsCodeForm" class="font-weight-light">Более подробно можете ознакомиться с условиями обработки <a href="https://dom24x7.ru/privacy.html" target="_blank">персональных данных</a>, а также с <a href="https://dom24x7.ru/policy.html" target="_blank">политикой использования данных</a></p>
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" :color="toast.color" @close="toastClose" />
  </v-container>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import uriParse from "url-parse";
import { mapState } from "vuex";
import Toast from "./ToastComponent";
import YandexMap from "./MapComponent";

export default {
  name: "AuthComponent",
  props: {
    boarding: Array,
    platform: String,
    miniapp: String
  },
  data() {
    return {
      mapCenter: [55.753933, 37.620735],
      countryCode: "RU",
      isLoaded: false,
      isMobile: false,
      isVKuser: false,
      mobile: null,
      vkUserId: null,
      address: null,
      flat: null,
      phoneRules: [
        (v) => !!v || "Необходимо указать номер телефона",
        (v) => /^\d{10}$/.test(v) || "Номер телефона должен быть валидным и состоять из 10 цифр",
      ],
      isReg: false,
      userFinded: false,
      showSmsCodeForm: false,
      smsCode: null,
      smsCodeRules: [
        (v) => !!v || "Необходимо указать код из смс",
        (v) => /^\d{4}$/.test(v) || "Код состоит из 4 цифр",
      ],
      toast: {
        show: false,
        text: null,
        color: "error",
      },
      sendBtnCaption: "Войти",
      inviteBtnCaption: "Зарегистрироваться",
      flatHint: "Номер квартиры указываете, если вы живете в многоквартирном доме",
      waitSeconds: 0,
      timer: null
    };
  },
  computed: {
    ...mapState(["client"]),
  },
  async created() {
    console.log("AuthPage created");

    const uri = uriParse(window.location.href, true);
    if (uri.query.vk_platform != null) {
      this.isMobile = uri.query.vk_platform.indexOf("mobile") != -1;
    }
    this.vkUserId = parseInt(uri.query.vk_user_id);

    //  ищем пользователя у нас, если ранее уже связали учетки ВК и у нас
    const user = await this.client.wrapEmit("user.get", { vkQueryParams: window.location.search.slice(1) });
    if (user != null && user.status != "ERROR") {
      this.isVKuser = true;
      this.sendBtnCaption = "Войти через ВК";
    }

    try {
      const onboarding = localStorage.getItem("neighbors.onboarding");
      if (!onboarding) {
        if (this.boarding != null && this.boarding.length != 0) {
          let slides = [];
          for (let item of this.boarding) {
            const imgBase64 = await this.client.wrapEmit("file.img2base64", { imgUrl: item.image });
            slides.push({
              media: { blob: `data:image/jpeg;base64,${imgBase64}`, type: "image" },
              title: item.title,
              subtitle: item.subtitle
            });
          }
          const resultShowOnboarding = await bridge.send("VKWebAppShowSlidesSheet", { slides: slides });
          if (resultShowOnboarding.result) {
            // запоминаем что пользователь посмотрел слайды и до нового крупного обновления их показывать не нужно
            localStorage.setItem("neighbors.onboarding", true);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async getVKmobile() {
      // ранее еще не авторизовывались и не связали аккаунты
      const result = await bridge.send("VKWebAppGetPhoneNumber");
      this.mobile = result.phone_number.substring(1);
      this.mobile2 = this.mobile;
    },
    checkPhone(value) {
      if (this.mobile == value) return true;
      return "Номера телефонов должны совпадать";
    },
    async auth() {
      this.isLoaded = true;
      if (this.isVKuser) {
        await this.authByVK();
      } else {
        if (this.isReg) {
          // регистрация нового пользователя
          await this.authByMobile();
        } else {
          await this.checkUserByMobile();
          if (this.userFinded) {
            // продолжаем авторизацию
            await this.authByMobile();
          }
        }
      }
      this.isLoaded = false;
    },
    async checkUserByMobile() {
      if (!/^\d{10}$/.test(this.mobile)) {
        console.warn("mobile not valid");
        return;
      }
      console.log("check user by mobile: ", this.mobile);
      const params = {
        mobile: `7${this.mobile}`,
        vkQueryParams: window.location.search.slice(1)
      };
      const result = await this.client.wrapEmit("user.get", params);
      this.userFinded = result.status != "ERROR";
      if (result.status == "ERROR") {
        if (result.body.code == "USER.005") {
          // пользователь на другой ноде
          this.client.clearHostCache();
          this.client.connect(result.body.extra.url);
          this.checkUserByMobile();
          return;
        }
        this.isReg = true;
      }
    },
    async authByVK() {
      // авторизовываем через ВК
      console.log("auth by VK");
      const result = await this.client.wrapEmit("user.authByVK", { vkQueryParams: window.location.search.slice(1), miniapp: this.miniapp });
      if (result.status == "ERROR") {
        this.toast.show = true;
        this.toast.text = result.message;
      }
    },
    async authByMobile() {
      if (this.isReg) {
        if (this.address == null || this.address.length == 0) {
          console.warn("address not valid");
          return;
        }
        // TODO: после перехода на карту более не актуальна проверка
        if (this.address.indexOf("@") != -1) {
          this.toast.show = true;
          this.toast.text = "Кажется вы вместо адреса квартиры ввели адрес электронной почты";
          return
        }
        if (this.countryCode != "RU") {
          this.toast.show = true;
          this.toast.text = "На текущий момент поддерживаются только дома из России";
        }
      }

      console.log("send mobile: ", this.mobile);
      const params = {
        mobile: `7${this.mobile}`,
        vkQueryParams: window.location.search.slice(1),
        address: this.address + (this.flat != null && this.flat.trim().length != 0 ? ` кв ${this.flat}` : ""),
        source: `${this.platform}|${this.miniapp}`
      };
      const result = await this.client.wrapEmit("user.auth", params);
      this.showSmsCodeForm = result.status == "OK";
      if (result.status == "ERROR") {
        if (result.body.code == "USER.005") {
          // пользователь на другой ноде
          this.client.clearHostCache();
          this.client.connect(result.body.extra.url);
          this.sendMobile();
          return;
        }
        this.toast.show = true;
        this.toast.text = result.message;
      }
    },
    async sendSmsCode() {
      this.isLoaded = true;
      const params = { mobile: `7${this.mobile}`, code: this.smsCode, vkQueryParams: window.location.search.slice(1) };
      console.log("send mobile with sms code: ", this.smsCode);
      const result = await this.client.wrapEmit("user.auth", params);
      if (result.status == "ERROR") {
        this.toast.show = true;
        this.toast.text = result.message;
      }
      this.isLoaded = false;
    },
    showPhoneField() {
      this.showSmsCodeForm = false;
    },
    disableSendSmsBtn() {
      if (this.smsCode == null) return true;
      return this.smsCode.trim().length != 4;
    },
    toastClose() {
      this.toast.show = false;
    },
    onMapClick(e) {
      console.log(e);
      this.address = e.address.formatted;
      this.countryCode = e.address.country_code;
      if (this.countryCode != "RU") {
        this.toast.show = true;
        this.toast.text = "На текущий момент поддерживаются только дома из России";
      }
    }
  },
  watch: {
    isReg() {
      this.sendBtnCaption = this.isReg ? "Зарегистрироваться" : "Войти";
      this.inviteBtnCaption = this.isReg ? "Авторизоваться" : "Зарегистрироваться";
      if ("geolocation" in navigator) {
        /* местоположение доступно */
        console.log("geolocation");
        navigator.geolocation.getCurrentPosition((position) => {
          this.mapCenter = [position.coords.latitude, position.coords.longitude];
          console.log(this.mapCenter);
        });
      } else {
        /* местоположение НЕ доступно */
        console.log("not geolocation");
      }
    },
    showSmsCodeForm() {
      if (this.showSmsCodeForm) {
        this.waitSeconds = 60;
        this.timer = setInterval(() => {
          this.waitSeconds--;
          if (this.waitSeconds == 0) clearInterval(this.timer);
        }, 1000);
      } else {
        if (this.timer) clearInterval(this.timer);
      }
    }
  },
  components: {
    Toast, YandexMap
  },
};
</script>

<style scoped>
  .top-spacer {
    height: 40px;
  }
</style>