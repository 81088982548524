<template>
  <div class="pa-3">
    <div class="pb-3" v-html="mail"></div>
    <template v-if="attachments.length != 0">
      <p><b>Приложенные файлы:</b></p>
      <ul>
        <li v-for="(link, index) in attachments" :key="index">
          <a :href="link">{{ link }}</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "MailPage",
  data() {
    return {
      request: null
    };
  },
  computed: {
    mail() {
      const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      };
      const replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
      };
      if (this.request == null) return "";
      const history = this.request.extra.history;
      const step5_1 = history.filter(item => item.step == "STEP5-1");
      if (step5_1.length == 0) return "текст письма не найден";
      const mail = step5_1[0].data;
      let msg = replaceAll(mail.text, "\n", "<br />");
      return msg;
    },
    attachments() {
      if (this.request == null) return [];
      const history = this.request.extra.history;
      const step5_1 = history.filter(item => item.step == "STEP5-1");
      if (step5_1.length == 0) return [];
      return step5_1[0].data.attachments;
    },
    ...mapState(["client"]),
  },
  async created() {
    try {
      this.request = await this.client.wrapEmit("miniapps/angry.info", { id: this.$route.params.id });
      this.setTitle(`Жалоба №${this.request.id}. Ответ`);
    } catch (error) {
      this.$router.go(-1);
    }
  },
  methods: {
    ...mapMutations(["setTitle"])
  }
}
</script>
