import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: "Дом24х7.Жалобы",
    pageName: null,
    user: null,
    category: null,
    company: null,
    house: null,
    flats: null,
    ready: {
      house: false,
      flats: false,
    }
  },
  getters,
  mutations,
  actions
});