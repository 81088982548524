import bridge from "@vkontakte/vk-bridge";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import SocketClient from "./lib/dom24x7Bridge/api/SocketClient";

const PRODUCTION_MODE = false;
Vue.config.productionTip = PRODUCTION_MODE

// Отправляет событие инициализации нативному клиенту
bridge.send("VKWebAppInit");

const NODE_HOST = "node.dom24x7.ru";
const client = new SocketClient(NODE_HOST);
store.commit("setClient", client);

client.on("login", async (data) => {
  console.log("emit login");
  store.commit("setUser", data.user);

  // указываем версию приложения
  await client.wrapEmit("service.appInfo", {
    version: "0.1.0",
    platform: "VK miniapp",
    platformVersion: ""
  });

  const user = await client.wrapEmit("user.info");
  store.commit("setUser", user);
  const houseId = user.houseId;

  const house = await client.wrapEmit("user.house");
  store.commit("setHouse", house);

  const flats = await client.wrapEmit("house.flats");
  store.commit("setFlats", flats);
  client.initChannel(`flats.${houseId}`);

  if (router.currentRoute.name == "auth") router.push("/");
});
client.on("logout", () => {
  console.log("emit logout");
  
  // закрываем каналы
  const user = store.state.user;
  if (user != null) {
    const houseId = user.houseId;
    const channels = [
      `flats.${houseId}`,
    ];
    for (let channel of channels) client.closeChannel(channel);

    store.commit("clearAll");
    client.clearHostCache();
  }

  if (router.currentRoute.name != "auth") router.push("/signin");
});
client.on("flats", flat => {
  store.commit("setFlat", flat.data);
});
client.on("channel.ready", data => {
  const info = { channel: data.name.split(".")[0], status: true };
  store.commit("setChannelsReady", info);
});

router.beforeEach((to, from, next) => {
  if (to.name == "auth") {
    // хотим перейти на страницу авторизации
    // если уже авторизованы, то перенаправляем на главную страницу
    if (store.state.user != null) next({ name: "requests" });
    next();
  } else {
    // любая страница кроме авторизации
    // если еще не авторизованы, то на страницу авторизации
    if (store.state.user == null) next({ name: "auth" });
    next();
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");