<template>
  <v-container fluid>
    <template v-if="request"></template>
    <v-card>
      <v-card-text>
        <b>Выбранная проблема:</b> {{ getRequestCategoryTitle() }}<br />
        <b>Выбранная компания:</b> {{ showCompany() }}<br />
        <b>Адрес вашего дома:</b> {{ getRequestHouseTitle() }}
      </v-card-text>
    </v-card>
    <br />
    <template v-if="hasTmpl()">
      <div v-for="tmpl in getTemplatesByCompanyType(false)" :key="tmpl.id">
        <p>Жалоба в {{ getCompanyType(tmpl.extra.toCompanyId) }}</p>
        <v-timeline align-top dense>
          <v-timeline-item color="pink" small v-if="showStepBlock('STEP1')">
            <v-row class="pt-1">
              <v-col>
                <strong>Получение шаблона</strong>
                <div class="text-caption">
                  Скачайте шаблон для заполнения
                  <v-btn color="success" block @click="downloadTmpl(tmpl)">Скачать</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="teal lighten-3" small v-if="showStepBlock('STEP2')">
            <v-row class="pt-1">
              <v-col>
                <strong>Загрузка жалобы</strong>
                <div class="text-caption">
                  Заполните данные в скачаном шаблоне и загрузите его для отправки. Жалоба будет отправлена в УК/ТСЖ
                  <template v-if="request.document == null">
                    <v-file-input v-model="document.file" show-size label="Загрузка заполненного шаблона" />
                    <v-btn color="primary" block @click="send" :disabled="document.file == null || document.loading" :loading="document.loading">Отправить</v-btn>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="pink" small v-if="showStepBlock('STEP3')">
            <v-row class="pt-1">
              <v-col>
                <strong>Жалоба загружена</strong>
                <div class="text-caption">
                  Заполненная жалоба загружена в систему для дальнейшей отправки в УК/ТСЖ
                  <v-btn color="success" block :href="request.document" v-if="request.document != null">Скачать</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="teal lighten-3" small v-if="showStepBlock('STEP4')">
            <v-row class="pt-1">
              <v-col>
                <strong>Ожидание ответа</strong>
                <div class="text-caption">
                  Жалоба отправлена в УК/ТСЖ, ожидаем ответа по ней
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="pink" small v-if="showStepBlock('STEP5-1')">
            <v-row class="pt-1">
              <v-col>
                <strong>Получен ответ</strong>
                <div class="text-caption">
                  От УК/ТСЖ получен ответ по жалобе
                  <v-btn color="pink" dark block :to="`/requests/${request.id}/mail`">Посмотреть</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-1" v-if="!showStepBlock('STEP6-1-1') && !showStepBlock('STEP6-1-2')">
              <v-col>
                <strong>Вас устроил ответ?</strong>
                <div class="text-caption pt-3">
                  <v-btn class="mx-2" fab dark color="green" @click="answerMark(true)">
                    <v-icon dark>mdi-thumb-up</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" fab dark color="red" @click="answerMark(false)">
                    <v-icon dark>mdi-thumb-down</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="pink" small v-if="showStepBlock('STEP5-2')">
            <v-row class="pt-1">
              <v-col>
                <strong>Ответ отсутствует</strong>
                <div class="text-caption">
                  В установленные законом сроки УК/ТСЖ не ответила на жалобу
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="teal lighten-3" small v-if="showStepBlock('STEP6-1-1')">
            <v-row class="pt-1">
              <v-col>
                <strong>Жалоба закрыта</strong>
                <div class="text-caption pt-3">
                  Спасибо что воспользовались сервисом
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="teal lighten-3" small v-if="showStepBlock('STEP6-1-2')">
            <v-row class="pt-1">
              <v-col>
                <strong>Результат по жалобе</strong>
                <div class="text-caption">
                  Пользователя не устроил ответ от УК/ТСЖ
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item color="indigo" small v-if="showStepBlock('STEP7')">
            <v-row class="pt-1">
              <v-col>
                <strong>Хотите продолжить дальше?</strong>
                <div class="text-caption">
                  Дальнейшая обработка жалобы и отправка в последующие инстанции будет доступна в новых версиях.
                  Мы сообщим вам когда появится возможность отправки жалоб в различные инстанции
                  <v-text-field v-model="subscribeEmail" label="Введите свой адрес электронной почты" :rules="emailRules" />
                  <v-btn color="primary" block @click="subscribe" :disabled="disabledSendBtn()">Отправить</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </div>
    </template>
    <template v-else><p class="font-weight-thin">не нашли нужный шаблон</p></template>
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" :color="toast.color" @close="closeToast" />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Toast from "@/lib/dom24x7Bridge/components/ToastComponent.vue";

export default {
  name: "TemplatePage",
  data() {
    return {
      request: null,
      companyTypes: [],
      templates: [],
      document: {
        file: null,
        base64: null,
        loading: false
      },
      subscribeEmail: null,
      emailRules: [
        (v) => /^\S+@\S+\.\S+$/.test(v) || "Адрес электронной почты должен быть валидным"
      ],
      toast: {
        color: "blue",
        show: false,
        text: ""
      }
    };
  },
  computed: {
    ...mapState(["client", "category", "company", "house"]),
  },
  async created() {
    try {
      this.request = await this.client.wrapEmit("miniapps/angry.info", { id: this.$route.params.id });
      this.templates = this.request.category.companyRefs;
      this.companyTypes = await this.client.wrapEmit("miniapps/angry.companyTypes", {});
      this.setTitle("Жалоба №" + this.request.id);
    } catch (error) {
      this.$router.go(-1);
    }
  },
  methods: {
    closeToast() {
      this.toast.show = false;
    },
    showCompany() {
      if (this.request == null) return "";
      if (this.request.house.company != null) {
        return this.request.house.company.name;
      } else if (this.request.category.companyRefs.length != 0) {
        return this.request.category.companyRefs[0].companyType.name;
      } else {
        return "";
      }
    },
    getRequestCategoryTitle() {
      if (this.request == null) return "";
      return this.request.category.title;
    },
    getRequestHouseTitle() {
      if (this.request == null) return "";
      return this.request.house.address;
    },
    getTmplLink(tmpl) {
      return tmpl.extra.tmpl;
    },
    async send() {
      console.log("отправка заполненной жалобы в УК/ТСЖ");
      try {
        this.document.loading = true;

        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        });
      
        if (this.document.file != null) {
          this.document.base64 = await toBase64(this.document.file);
          const file = {
            base64: this.document.base64.split(";")[1].split(",")[1],
            name: this.document.file.name,
            size: this.document.file.size,
            type: "file"
          };
          let result = await this.client.wrapEmit("file.upload", file);
          result = await this.client.wrapEmit("miniapps/angry.send", { id: this.request.id, fileName: file.name, fileLink: result.file.link });
          if (result.status == "OK") {
            this.request = result.angry;
            // this.$forceUpdate();
          }
        }
      } finally {
        this.document.loading = false;
      }
    },
    async downloadTmpl(tmpl) {
      await this.step("STEP2");
      window.location.href = this.getTmplLink(tmpl);
    },
    async step(code) {
      const result = await this.client.wrapEmit("miniapps/angry.step", { id: this.request.id, code });
      if (result.status == "OK") {
        this.request = result.angry;
      }
    },
    hasTmpl() {
      if (this.request == null) return false;
      return this.templates.length != 0;
    },
    getCompanyType(companyTypeId) {
      const result = this.companyTypes.filter(item => item.id == companyTypeId);
      if (result.length == 0) return "компанию";
      return result[0].name;
    },
    getTemplatesByCompanyType(all = true) {
      let companyTypeId;
      if (this.request.house.company != null) companyTypeId = this.request.house.company.typeId;
      if (this.request.category.companyRefs.length != 0) companyTypeId = this.request.category.companyRefs[0].companyType.id;
      if (companyTypeId == null) return this.templates;
      const list = this.templates.filter(item => item.companyTypeId == companyTypeId).sort((item1, item2) => {
        const step1 = item1.extra.step;
        const step2 = item2.extra.step;
        if (step1 == null || step2 == null) return 0;
        if (step1 > step2) return 1;
        if (step1 < step2) return -1;
        return 0;
      });
      return all ? list : list.filter(item => item.extra.step == 1);
    },
    showStepBlock(step) {
      return this.request.extra.history.filter(item => item.step == step).length != 0;
    },
    async answerMark(status) {
      if (status) {
        // ответ от УК полностью устроил пользователя, зявку можно закрыть
        await this.step("STEP6-1-1");
        const result = await this.client.wrapEmit("miniapps/angry.close", { id: this.request.id });
        if (result.status == "OK") {
          this.request = result.angry;
        }
      } else {
        // ответ от УК пользователя не устроил, эскалируем жалобу
        await this.step("STEP6-1-2");
        await this.step("STEP7");
      }
    },
    async subscribe() {
      if (this.disabledSendBtn()) return;
      const result = await this.client.wrapEmit("miniapps/angry.subscribe", { id: this.request.id, email: this.subscribeEmail });
      if (result.status == "OK") {
        this.toast.color = "blue";
        this.toast.text = "Вы успешно подписались на обновления";
        this.toast.show = true;
      } else {
        this.toast.color = "red";
        this.toast.text = "Не удалось подписаться на обновления. Попробуйте позже";
        this.toast.show = true;
      }
    },
    disabledSendBtn() {
      return !/^\S+@\S+\.\S+$/.test(this.subscribeEmail);
    },
    ...mapMutations(["setTitle"]),
  },
  components: {
    Toast
  }
};
</script>