<template>
  <v-app-bar app color="primary" dark>
    <v-btn v-if="showNavBack" icon @click="goBack">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="createLink != null" icon :to="createLink">
      <v-icon >mdi-plus</v-icon>
    </v-btn>
    <v-btn icon to="/settings">
      <v-icon >mdi-cog</v-icon>
    </v-btn>
    <div v-if="isMobile" class="header-right-spacer"></div>
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" color="warning" @close="toastClose" />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import uriParse from "url-parse";
import Toast from "../components/ToastComponent";

export default {
  name: "HeaderLayer",
  props: {
    createLink: String,
    showNavBack: {
      type: Boolean,
      default: false
    },
    goBack: Function
  },
  data() {
    return {
      isMobile: false,
      toast: {
        show: false,
        text: "Метод в разработке",
      },
    };
  },
  computed: {
    title() {
      return this.pageName;
    },
    ...mapState(["appName", "pageName", "client"]),
  },
  created() {
    const uri = uriParse(window.location.href, true);
    if (uri.query.vk_platform != null) {
      this.isMobile = uri.query.vk_platform.indexOf("mobile") != -1;
    }
  },
  methods: {
    toastClose() {
      this.toast.show = false;
    }
  },
  components: {
    Toast
  },
};
</script>

<style scoped>
  .header-right-spacer {
    width: 80px;
  }
</style>