<template>
  <v-app>
    <HeaderLayer v-if="user != null" createLink="/create/category" :showNavBack="showNavBack()" :goBack="goBack" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import HeaderLayer from "@/lib/dom24x7Bridge/layers/HeaderLayer";

export default {
  name: "App",
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    showNavBack() {
      switch (this.$route.name) {
        case "requests":
          return false;
        default:
          return true;
      }
    },
    goBack() {
      if (this.$route.name == "request") {
        this.$router.push({ name: "requests" });
      } else {
        this.$router.go(-1);
      }
    }
  },
  components: {
    HeaderLayer
  }
}
</script>