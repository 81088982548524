<template>
  <AuthComponent :boarding="boarding" platform="vk" miniapp="angry">
    Мы просим от вас только:
    <ul>
      <li>номер мобильного телефона для авторизации и отправки кода безопасности (также можем отправлять вам экстренные сообщения в случае необходимости);</li>
      <li>ваш домашний адрес для определения вашей УК/ТСЖ, а также сбытовых компаний и контактов служб, куда необходимо направлять жалобы.</li>
    </ul>
    Мы не передаем ваши данные третьим лицам и если это, все же, будет необходимо, то дополнительно запросим у вас на это явное разрешение.
  </AuthComponent>
</template>

<script>
import AuthComponent from "../lib/dom24x7Bridge/components/AuthComponent.vue";

export default {
  name: "AuthPage",
  data() {
    return {
      boarding: [
        {
          image: "https://805422.selcdn.ru/dom24x7-storage/other/onboarding/vk/angry/p1.jpeg",
          title: "Неправомерные начисления от УК?",
          subtitle: "Сервис для формирования жалобы и отправки в нужные службы"
        },
        {
          image: "https://805422.selcdn.ru/dom24x7-storage/other/onboarding/vk/angry/p2.jpeg",
          title: "Постоянно скачет напряжение?",
          subtitle: "Сформируйте письмо с претензией и направьте в Россети"
        },
        {
          image: "https://805422.selcdn.ru/dom24x7-storage/other/onboarding/vk/angry/p3.jpeg",
          title: "Крысы бегают по району?",
          subtitle: "Узнайте, куда жаловаться, и отправьте коллективное письмо"
        }
      ]
    };
  },
  components: {
    AuthComponent
  }
}
</script>
