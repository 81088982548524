import Vue from "vue";
import Router from "vue-router";

import RequestsListPage from "@/pages/RequestsListPage";
import RequestsCreatePage from "@/pages/RequestsCreatePage";
import CategoryPage from "@/pages/CategoryPage";
import RequestPage from "@/pages/RequestPage";

import MailPage from "@/pages/MailPage.vue";

import AuthPage from "@/pages/AuthPage";
import SettingsPage from "@/pages/SettingsPage";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/signin", name: "auth", component: AuthPage },
    { path: "/settings", name: "settings", component: SettingsPage },
    
    { path: "/", name: "requests", component: RequestsListPage },

    { path: "/create", name: "create", component: RequestsCreatePage },
    { path: "/create/category", name: "createCategory", component: CategoryPage },
    { path: "/create/category/:categoryId", name: "createSubCategory", component: CategoryPage },

    { path: "/edit/:id/category", name: "editcategory", component: CategoryPage },
    { path: "/edit/:id/category/:categoryId", name: "editSubCategory", component: CategoryPage },
    
    { path: "/requests/:id", name: "request", component: RequestPage },

    { path: "/requests/:id/mail", name: "mail", component: MailPage }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});