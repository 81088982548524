<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <b>Выбранная проблема:</b> {{ category.title }}<br />
        <b>Выбранная компания:</b> {{ showCompany() }}<br />
        <b>Адрес вашего дома:</b> {{ house.address }}
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>Вы ранее уже подавали жалобу?</v-card-title>
      <v-card-text>
        <template v-for="item in companyTypes">
          <v-checkbox v-if="showCompanyType(item)" class="mt-0 pt-0" v-model="companySentList" :key="item.id" :label="item.name" :value="item.id" />
        </template>
      </v-card-text>
    </v-card>
    <v-card v-if="showSubscribeCard()" class="mt-2" color="blue" dark>
      <v-card-title>
        Подписаться на уведомление
      </v-card-title>
      <v-card-subtitle>
        Мы сообщим вам когда появится возможность отправки жалоб в различные инстанции
        <v-text-field
          v-model="subscribeEmail"
          label="Введите свой адрес электронной почты"
          :rules="emailRules"
          />
      </v-card-subtitle>
    </v-card>
    <br />
    <v-btn v-if="hasTmpl()" x-large color="success" block @click="onClick" :disabled="disabledSendBtn()">Сохранить жалобу</v-btn>
    <template v-else><p class="font-weight-thin">Не нашли нужный шаблон. Сохранить шаблон нельзя</p></template>
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" :color="toast.color" @close="toast.show = !toast.show" />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Toast from "@/lib/dom24x7Bridge/components/ToastComponent";

export default {
  name: "TemplatePage",
  data() {
    return {
      companyTypes: [],
      companySentList: [],
      subscribeEmail: null,
      emailRules: [
        (v) => /^\S+@\S+\.\S+$/.test(v) || "Адрес электронной почты должен быть валидным"
      ],
      toast: {
        show: false,
        text: null,
        color: null
      },
    };
  },
  computed: {
    ...mapState(["client", "category", "company", "house"]),
  },
  async created() {
    this.setTitle("Создание жалобы");
    this.companyTypes = await this.client.wrapEmit("miniapps/angry.companyTypes");
  },
  methods: {
    showCompany() {
      if (this.house.company != null) {
        return this.house.company.name;
      } else if (this.category.companyRefs.length != 0) {
        return this.category.companyRefs[0].companyType.name;
      } else {
        return "";
      }
    },
    showCompanyType(companyType) {
      if ([1, 2].includes(companyType.id)) {
        if (this.house.company != null) {
          const typeId = this.house.company.type.id;
          return typeId == companyType.id;
        }
      }
      return true;
    },
    showSubscribeCard() {
      return this.companySentList.includes(1) || this.companySentList.includes(2);
    },
    async onClick() {
      try {
        let extra = { companies: [] };
        console.log(this.companySentList);
        for (let item of this.companySentList) {
          extra.companies.push({ companyTypeId: item, sender: "user" });
        }
        if (this.showSubscribeCard()) {
          extra.subscribe = { email: this.subscribeEmail };
        }

        const request = await this.client.wrapEmit("miniapps/angry.save", { categoryId: this.category.id, extra });
        this.$router.push({ name: "request", params: { id: request.id } });
      } catch (error) {
        this.toast.text = "Не удалось сохранить жалобу";
        this.toast.color = "error";
        this.toast.show = true;
      }
    },
    hasTmpl() {
      const companyRefs = this.category.companyRefs;
      if (companyRefs == null || companyRefs.length == 0) return false;
      return true;
    },
    disabledSendBtn() {
      return this.showSubscribeCard() && !/^\S+@\S+\.\S+$/.test(this.subscribeEmail);
    },
    ...mapMutations(["setTitle"]),
  },
  components: {
    Toast,
  },
};
</script>