<template>
  <yandex-map
    :coords="mapCenterCoords"
    :zoom="zoom || 15"
    :style="`height: ${height || '165px'}`"
    :settings="{ apiKey }"
    :controls="controls"
    :options="options"
    @click="onClick"
  >
    <ymap-marker v-for="marker in mapMarkers" :key="marker.id" :coords="marker.coords" :marker-id="marker.id" />
  </yandex-map>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";

import { yandexApiKey } from "../constants/api.js";

export default {
  name: "MapComponent",
  components: {
    yandexMap,
    ymapMarker,
  },
  props: {
    zoom: Number,
    coords: Array,
    height: String,
    markers: Array,
  },
  data: () => ({
    mapCenterCoords: null,
    mapMarkers: [],
    apiKey: yandexApiKey,
    controls: ["fullscreenControl"],
    options: {
      suppressMapOpenBlock: true
    }
  }),
  created() {
    this.mapCenterCoords = this.coords;
    if (this.markers != null && this.markers.length != 0) {
      this.mapMarkers = this.markers;
    }
  },
  methods: {
    async onClick(e) {
      this.mapCenterCoords = e.get("coords");
      this.mapMarkers = [{ id: 1, coords: this.mapCenterCoords }];
      
      const coord = this.mapCenterCoords[1] + "," + this.mapCenterCoords[0];
      const geocodeUrl = `https://geocode-maps.yandex.ru/1.x/?apikey=${this.apiKey}&geocode=${coord}&format=json`;
      const result = await fetch(geocodeUrl);
      const json = await result.json();
      const address = json.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address;

      this.$emit("click", { coord: this.mapCenterCoords, address });
    }
  },
  watch: {
    coords() {
      this.mapCenterCoords = this.coords;
    }
  }
};
</script>