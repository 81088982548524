<template>
  <v-container fluid>
    <v-row dense v-if="list.length != 0">
      <v-col cols="12" v-for="item in list" :key="item.id">
        <v-card>
          <v-card-title @click="gotoRequestCard(item)">Жалоба №{{ item.id }}</v-card-title>
          <v-card-text @click="gotoRequestCard(item)">
            <b>Выбранная проблема:</b> {{ item.category.title }}<br />
            <b>Выбранная компания:</b> {{ showCompany(item) }}<br />
            <b>Адрес вашего дома:</b> {{ item.house.address }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn> -->
            <v-btn icon @click="showRefModal(item)"><v-icon>mdi-share-variant-outline</v-icon></v-btn>
            <v-btn icon @click="onDelClick(item)"><v-icon>mdi-delete-outline</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <p v-else>Список жалоб пуст</p>
    <Toast v-if="toast.show" :show="toast.show" :text="toast.text" :color="toast.color" @close="closeToast" />
    <v-bottom-sheet v-model="sheet">
      <RefCard link="https://vk.com/app51805704" />
    </v-bottom-sheet>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title style="word-break: break-word">Вы действительно хотите удалить жалобу?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="delRequestClick">Удалить</v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">Отменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import RefCard from "@/lib/dom24x7Bridge/components/RefCardComponent";
import Toast from "@/lib/dom24x7Bridge/components/ToastComponent";

export default {
  name: "RequestsListPage",
  data() {
    return {
      sheet: false,
      dialog: false,
      delRequest: null,
      list: [],
      toast: {
        show: false,
        text: null,
        color: null
      }
    };
  },
  computed: {
    ...mapState(["client"]),
  },
  async created() {
    this.setTitle("Жалобы");
    const result = await this.client.wrapEmit("miniapps/angry.list");
    if (result.status == "ERROR") return;
    this.list = result;
  },
  methods: {
    closeToast() {
      this.toast.show = !this.toast.show;
    },
    showCompany(request) {
      if (request.house.company != null) {
        return request.house.company.name;
      } else if (request.category.companyRefs.length != 0) {
        return request.category.companyRefs[0].companyType.name;
      } else {
        return "";
      }
    },
    showRefModal(request) {
      // TODO: помечаем на ноде, что пользователь расшарил жалобу на своих соседей
      console.log(request);
      this.sheet = true;
    },
    gotoRequestCard(request) {
      this.$router.push({ name: 'request', params: { id: request.id } });
    },
    onDelClick(request) {
      this.dialog = true;
      this.delRequest = request;
    },
    async delRequestClick() {
      if (this.delRequest == null) return;
      try {
        await this.client.wrapEmit("miniapps/angry.del", { id: this.delRequest.id });
        this.list = await this.client.wrapEmit("miniapps/angry.list");
        this.dialog = false;
      } catch (error) {
        this.toast.text = "Не удалось удалить жалобу";
        this.toast.color = "error";
        this.toast.show = true;
      }
    },
    ...mapMutations(["setTitle"]),
  },
  components: {
    Toast, RefCard
  },
}
</script>